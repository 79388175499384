import axios from 'axios';
import CryptoJS from 'crypto-js';

const URL = process.env.REACT_APP_URL;
const App_Token = CryptoJS.AES.encrypt(process.env.REACT_APP_LEALTAD_TOKEN, process.env.REACT_APP_LEALTAD_SECRET).toString();


const validarSesion = async (username, rfc, token) => {
    try {
        const response = await axios.get(`${URL}/tiendas/${rfc}/sesion`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "username": username,
                'App_Token': App_Token,
                'Authorization': `Bearer ${token}`
            }
        });
        if(response.data.meta.ok) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export { validarSesion };