import { getDoc, doc} from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

import db from '../utils/Firebase';


async function getTienda (alias) {
    const docRef = doc(db, "tiendas_configuracion", alias);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return docSnap.data();
    } else {
      return null;
    }
}

async function getImageUrlStorage (fileName) {
  // Create a reference to the file we want to download
  const storage = getStorage();
  const starsRef = ref(storage, fileName);
  
  // Get the download URL
  try {
    const url = await getDownloadURL(starsRef);
    return url;
  } catch (error) {
    return null;
  }
}

export { getTienda, getImageUrlStorage }