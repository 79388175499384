import 'date-fns';
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CryptoJS from 'crypto-js';
import { Redirect, NavLink } from 'react-router-dom';
import Loading from '../utils/Loading';
import ModalComponente from '../utils/Modal';
import Header from './Header';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import ReCaptchaV2 from 'react-google-recaptcha'
import '../styles/Registro.css';

// const initialFormData = Object.freeze({
//   nombre: "",
//   apellidoPaterno: "",
//   apellidoMaterno: "",
//   email: "",
//   cp: "",
//   telefono: "",
//   fecha_nacimiento: null,
//   avisoDePrivacidad: false,
//   terminosYCondiciones: false
// }); 

const URL = process.env.REACT_APP_URL;
const App_Token = CryptoJS.AES.encrypt(process.env.REACT_APP_LEALTAD_TOKEN, process.env.REACT_APP_LEALTAD_SECRET).toString();


const Registro = ({tienda, handleAlerta, cliente, handleInputChange, resetCliente, resetTerminosYAviso}) => {
    const [loading, setLoading] = useState(false);
    const [ modal, setModal] = useState(false);
    const [ruta, setRuta] = useState(`/${tienda.alias}/registro`);
    const recaptchaRef = React.useRef();

    const handleModal = () => {
      setModal(!modal);
    }

    useEffect(() => {
      resetTerminosYAviso();
    },[]);


    const handleDateChange = (date) => {
      const event = {
        target: {
          name: 'fecha_nacimiento',
          type: 'date',
          value: date 
        }
      }
      handleInputChange(event);
    };

    const enviarSMSCliente = async (event) => {
      event.preventDefault();
      const responseValidarCampos = await validarCamposFormulario();
      
      if(responseValidarCampos.ok === true){
          setLoading(true);
          recaptchaRef.current.reset();
          const token = await getRecaptchaToken();
            const telefono = cliente.telefono;
            const data = {
              "telefono": telefono,
              "token": token
            };
            axios.post(`${URL}/clientes/enviar-sms/${tienda.rfc}`,JSON.stringify(data),
            {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'App_Token': App_Token
              }
            }).then((response)=>{
              setLoading(false);
              if(response.data.meta.ok) {
                setModal(!modal);
              }else {
                handleAlerta({'active': true, 'type': 'warning', 'message': 'Ocurrió un error al enviar código de verificación'})
              }
            }).catch((err)=>{
              setLoading(false);
              handleAlerta({'active': true, 'type': 'warning', 'message': 'Ocurrió un error al enviar código de verificación'})
            })
        } else {
          handleAlerta({'active': true, 'type': responseValidarCampos.type, 'message': responseValidarCampos.message});
        }
  }

  const validarCamposFormulario = async () => {
    if(cliente.telefono.length === 10){
    if(cliente.fecha_nacimiento != null){
      if(cliente.terminos_y_condiciones != false){
        if(cliente.aviso_de_privacidad != false) {
            return {
              'ok': true,
              'type': '',
              'message': ''
            }
          }else {
            return {
              'ok': false,
              'type': 'info',
              'message': 'Debes aceptar el aviso de privacidad'
            }
          }
        } else {
          return {
            'ok': false,
            'type': 'info',
            'message': 'Debes aceptar los términos y condiciones'
          }
        }
      } else {
        return {
          'ok': false,
          'type': 'info',
          'message': 'Selecciona tu fecha de nacimiento'
        }
      }
    } else {
      return {
        'ok': false,
        'type': 'warning',
        'message': 'Número de celular no válido'
      }
    }
  };


    const getRecaptchaToken = async () => {
      const token = await recaptchaRef.current.executeAsync();
      return token;
    }

    const validarCodigo = async (codigo) => {
      if(codigo === "") {
        handleAlerta({'active': true, 'type': 'warning', 'message': 'Ingresar código'})
      } else {
        const fecha = new Date(cliente.fecha_nacimiento);
      const fechaNacimiento = `${fecha.getFullYear()}-${fecha.getMonth()+1}-${fecha.getDate()}`;
      const data = {
        'cliente': {
          "nombre": `${cliente.nombre} ${cliente.apellidoPaterno} ${cliente.apellidoMaterno}`,
          "email": cliente.email,
          "cp": cliente.cp,
          "telefono": cliente.telefono,
          "fecha_nacimiento": fechaNacimiento,
          "codigo": codigo
        },
        'alias': tienda.alias

      };
      setLoading(true);
  
      axios.post(`${URL}/clientes/registrar/${tienda.rfc}`,JSON.stringify(data),{
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'App_Token': App_Token
      }
      }).then(function(response){
        setLoading(false);
          if(response.data.meta.ok) {
            setModal(!modal);
            handleAlerta({'active': true, 'type': 'success', 'message': response.data.meta.message})
            resetCliente();
            if(tienda.thankyou_page_link.activo){
              setTimeout(() => {
                window.location.href = tienda.thankyou_page_link.url;
              }, 3000);
            }else{
              setRuta(`/${tienda.alias}/gracias/${cliente.telefono}`);
            }
          }else {
            handleAlerta({'active': true, 'type': 'info', 'message': response.data.meta.message})
          }
      }).catch(function(error){
        setLoading(false);
          handleAlerta({'active': true, 'type': 'warning', 'message': error.response.data.meta.message})
      });
      }
    }

    return(
      <Container component="main" maxWidth="lg">
      <div className="registro-container">
        <Header tienda={tienda}/> 
          <Redirect to={ruta} />      
          <Loading loading={loading} />
          <ModalComponente handleModal={handleModal} showModal={modal} validarCodigo={validarCodigo}/>
            <Container component="main" maxWidth="sm">
            <Grid container className="box-container">
              <Grid item xs={12} sm={12} md={12} >
              <h1 className="registro-title">Registro</h1>
              <Grid container className="box-formulario">
                <form onSubmit={enviarSMSCliente} className="formulario">
                  <Grid item xs={12} sm={12} md={12} >
                    <label>Nombre</label>
                    <div className="box-input">
                      <input type="text" defaultValue={cliente.nombre} inputMode="latin-name" className="form-input" name="nombre" onChange={(event)=> handleInputChange(event)} required/>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                  <Grid container spacing={3}>
                  <Grid item xs={6} sm={6} md={6}>
                    <label>Apellido paterno</label>
                    <div className="box-input">
                      <input type="text" defaultValue={cliente.apellidoPaterno} inputMode="latin-name" className="form-input" name="apellidoPaterno" onChange={(event)=> handleInputChange(event)} required/>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <label>Apellido materno</label>
                    <div className="box-input">
                      <input type="text" defaultValue={cliente.apellidoMaterno} inputMode="latin-name" className="form-input" name="apellidoMaterno" onChange={(event)=> handleInputChange(event)} required/>
                    </div>
                  </Grid>
                  </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} >
                    <label>E-mail</label>
                    <div className="box-input">
                      <input type="email" defaultValue={cliente.email} inputMode="email" className="form-input" name="email" onChange={(event)=> handleInputChange(event)} required/>
                    </div>
                  </Grid>
                  {
                    tienda.registro_codigo_postal.activo && 
                    <Grid item xs={12} sm={12} md={6}>
                      <label>Código postal</label>
                      <div className="box-input">
                        <input type="text" defaultValue={cliente.cp} inputMode="numeric" className="form-input" name="cp" onChange={(event)=> handleInputChange(event)} required/>
                      </div>
                  </Grid>
                  }

                  <Grid item xs={12} sm={12} md={6} >
                    <label>Celular</label>
                    <div className="box-input">
                      <input type="text" defaultValue={cliente.telefono} inputMode="tel" className="form-input" name="telefono" onChange={(event)=> handleInputChange(event)} required />
                    </div>
                  </Grid>
                  {
                    tienda.registro_fecha_nacimiento.activo && 
                    <Grid item xs={12} sm={12} md={12} >
                      <label>Fecha de nacimiento</label>
                    <div className="box-input">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                        required
                          className="form-input"
                          disableFuture
                          openTo="year"
                          id="date-picker-dialog"
                          format="yyyy/MM/dd"
                          label=""
                          views={["year", "month", "date"]}
                          value={cliente.fecha_nacimiento}
                          onChange={handleDateChange}
                        />
                      </MuiPickersUtilsProvider>
                      </div>
                    </Grid>
                  }
                  <Grid item xs={12} sm={12} md={12} >
                    <label className="b-checkBox">
                      <input type="checkbox" name="terminos_y_condiciones" onChange={(event)=> handleInputChange(event)}/>
                      <span className="checkmark"></span>
                      <NavLink to={`/${tienda.alias}/terminos-y-condiciones`} >Términos y condiciones</NavLink>
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} >
                    <label className="b-checkBox">
                      <input type="checkbox"  name="aviso_de_privacidad" onChange={(event)=> handleInputChange(event)}/>
                      <span className="checkmark"></span>
                      <NavLink to={`/${tienda.alias}/aviso-de-privacidad`}>Aviso de privacidad</NavLink>
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} className="box-btn">
                    <ReCaptchaV2 
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_SITE_KEY} 
                    size="invisible"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} className="box-btn">
                    <button type="submit" className="submit-btn">Registrarme</button>
                  </Grid>
                </form>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>
        </Container>
    );
}

export default Registro;

