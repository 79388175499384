import axios from 'axios';
import CryptoJS from 'crypto-js';

const URL = process.env.REACT_APP_URL;
const App_Token = CryptoJS.AES.encrypt(process.env.REACT_APP_LEALTAD_TOKEN, process.env.REACT_APP_LEALTAD_SECRET).toString();

const validarSesionUsuario = async (rfc, celular, token) => {
    try {
        const data = {
            "celular": celular,
        };
        const response = await axios.post(`${URL}/clientes/${rfc}/sesion`, JSON.stringify(data),{
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                'App_Token': App_Token,
                'Authorization': `Bearer ${token}`
            }
        });
        if(response.data.meta.ok && response.status === 200) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export { validarSesionUsuario };
