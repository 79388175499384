import React, { useEffect, useState } from 'react';
import '../styles/Home.css';
import {Helmet} from "react-helmet";
import Home from './Home';
import PageNotFound from './PageNotFound';
import Admin from './admin/Admin';
import MiCuenta from './perfil/MiCuenta';
import Auth from './perfil/Auth';
import Registro from './Registro';
import TerminosYCondiciones from './TerminosYCondiciones';
import AvisoDePrivacidad from './AvisoDePrivacidad';
import ThankYouPage from './ThankYouPage';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Redirect } from "react-router-dom";
import Loading from '../utils/Loading';
import Alerta from '../utils/Alerta';
import { useParams } from 'react-router-dom';
import { getTienda } from '../models/Firebase';


const initialFormData = Object.freeze({
  nombre: "",
  apellidoPaterno: "",
  apellidoMaterno: "",
  email: "",
  cp: "",
  telefono: "",
  fecha_nacimiento: null,
  aviso_de_privacidad: false,
  terminos_y_condiciones: false
}); 

const Tienda = () => { 
    const [loading, setLoading] = useState(false);
    const [tienda, setTienda] = useState(false);
    const [tiendaNotFound, setTiendaNotFound] = useState(false);
    const [cliente, setCliente ] = useState(initialFormData);
    const { alias } = useParams();
    const [alerta, setAlerta] = useState({'active': false, 'type': '', 'message': ''});
    const [ruta, setRuta ] = useState(`/${alias}`);

    useEffect(() => {
      const getTiendaFirebase = async () => {
        setLoading(true);
        const tiendaInfo = await getTienda(alias);
        if(tiendaInfo != null){
          if(tiendaInfo.tienda_configurada){
            setLoading(false);
            setTienda(tiendaInfo);
            document.documentElement.style.setProperty('--primerColor',tiendaInfo.color);
            setTiendaNotFound(false);
          }else {
            setLoading(false);
            setTienda(tiendaInfo);
            setTiendaNotFound(false);
            setRuta(`/${alias}/admin/`);
          }
        }else {
          setTiendaNotFound(true);
          setLoading(false);
        }
      }

      getTiendaFirebase();

      localStorage.setItem('alias', alias);
      
    },[alias])

    const updateTienda = async () => {
      setLoading(true);
      const tiendaInfo = await getTienda(alias);
      if(tiendaInfo != null){
        setLoading(false);
        setTienda(tiendaInfo);
        document.documentElement.style.setProperty('--primerColor',tiendaInfo.color);
      }else {
        setLoading(false);
      }
    }

    const handleAlerta = (alertaInfo) => {
       setAlerta(alertaInfo);
    }

    const handleInputChange = (event) => {
      const inputType = event.target.type;
      if(inputType === 'checkbox') {
        setCliente({
          ...cliente,
            [event.target.name]: event.target.checked
        })
      } else {
      setCliente({
        ...cliente,
          [event.target.name]: event.target.value
        })
      }
    }

    const resetCliente = () => {
      setCliente(initialFormData);
    }
    const resetTerminosYAviso = () => {
      setCliente({
        ...cliente,
          'terminos_y_condiciones': false,
          'aviso_de_privacidad': false
      })
    }

    return(
        <div className="tienda-container">
          {
            tienda && 
            tienda.tienda_configurada != true &&
              <Redirect to={ruta} />

          }
          <Loading loading={loading} />
        {
          tienda && 
          tienda.tienda_configurada &&
          <Helmet>
            <meta charSet="utf-8" />
            <title>{tienda.alias}</title>
            <link rel="icon" href={tienda.logo} />
            <link rel="apple-touch-icon" href={tienda.logo} />
            <meta
              name="description"
              content={tienda.titulo_1.contenido}
            />
          </Helmet>
        }

          <Alerta alerta={alerta} handleAlerta={handleAlerta}/>
          {
                tienda &&
                tienda.tienda_configurada &&
                  <div className="rutas-container">
                    <Route
                      exact
                      path="/:alias/"
                      render={(props) => (
                        <Home {...props} tienda={tienda}/>
                      )}
                    />
                    <Route
                      exact
                      path="/:alias/registro/"
                      render={(props) => (
                        <Registro {...props} tienda={tienda} handleAlerta={handleAlerta} cliente={cliente} handleInputChange={handleInputChange} resetCliente={()=>resetCliente()} resetTerminosYAviso={()=>resetTerminosYAviso()} />
                      )}
                    />
                    <Route
                      exact
                      path="/:alias/terminos-y-condiciones/"
                      render={(props) => (
                        <TerminosYCondiciones {...props} tienda={tienda} />
                      )}
                    />
                    <Route
                      exact
                      path="/:alias/aviso-de-privacidad/"
                      render={(props) => (
                        <AvisoDePrivacidad {...props} tienda={tienda} />
                      )}
                    />
                    <Route
                      exact
                      path="/:alias/gracias/:celular"
                      render={(props) => (
                        <ThankYouPage {...props} tienda={tienda} />
                      )}
                    />
                  </div>

              }
              {
                tienda && 
                <div className="rutas-container">
                  <Route
                  path="/:alias/admin/"
                  render={(props) => (
                    <Admin {...props} tienda={tienda} handleAlerta={handleAlerta} updateTienda={updateTienda}/>
                  )}
                />  
                </div>                  
              }
              {
                tienda && 
                  tienda.canjeo_de_puntos_activado && 
                    <div className="rutas-container">
                      <Route
                      path="/:alias/mi-cuenta/"
                      render={(props) => (
                        <MiCuenta {...props} tienda={tienda} handleAlerta={handleAlerta}/>
                      )}
                    />  
                    </div>                  
              }
              {
                tienda && 
                  tienda.canjeo_de_puntos_activado && 
                    <div className="rutas-container">
                      <Route
                      path="/:alias/auth/"
                      render={(props) => (
                        <Auth {...props} tienda={tienda} handleAlerta={handleAlerta}/>
                      )}
                    />  
                    </div>                  
              }
              {
                tiendaNotFound &&
                <div className="rutas-container">
                  <Route
                    path="/:alias"
                    render={(props) => (
                      <PageNotFound alias={alias}/>
                    )}
                  /> 
                  <Route
                    path="/:alias/admin/"
                    render={(props) => (
                      <Admin {...props} tienda={tienda} handleAlerta={handleAlerta} updateTienda={updateTienda}/>
                    )}
                  />                    
                </div>
              }       
        </div>
    );
};

export default Tienda;


