
import '../styles/Alerta.css';

const Alerta = ({alerta, handleAlerta}) => {

    
    const checkIcon = ()=>{
        if(alerta.type==="success"){
            return "check"
        }else if(alerta.type==="info"){
            return "info"
        }else if(alerta.type==="warning"){
            return "exclamation"
        }else{
            return "times"
        }
    }
    return(
        <div className={"alerta-container " + (alerta.active ? "active" : "") }>
        <div className="b-fade" onClick={() => handleAlerta({'active': false, 'message': ''})}></div>
            <div className={`b-alerta ${alerta.type}`}>
                <div className="b-body">
                    <i className={`alert-icon ${alerta.type} fa fa-${checkIcon()}-circle`}></i>
                        <span className="b-massage">{alerta.message}</span>
                    <i className="fas fa-times" onClick={() => handleAlerta({'active': false, 'message': ''})}></i>
                </div>
            </div>
        </div>
    );
}

export default Alerta;