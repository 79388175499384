import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import "react-markdown-editor-lite/lib/index.css";
import '../../styles/Login.css';


const Personalizacion = ({login}) => {

    const loginSubmit = (event) => {
        event.preventDefault();
        login(event);
    }

    return(
        <div className="login-container">
            <h1 className="title">Iniciar Sesión</h1>
            <Container component="main" maxWidth="sm">
                <Grid container className="box-container">
                    <Grid item xs={12} sm={12} md={12} >
                        <Grid container className="box-formulario">
                            <form onSubmit={loginSubmit} className="formulario">
                                <Grid item xs={12} sm={12} md={12} >
                                    <label>Usuario</label>
                                    <div className="box-input">
                                        <input type="text" inputMode="latin-name" className="form-input" name="username" required/>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} >
                                    <label>Contraseña</label>
                                    <div className="box-input">
                                        <input type="password" inputMode="latin-name" className="form-input" name="password" required/>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} className="box-btn">
                                    <button type="submit" className="submit-btn">Iniciar Sesión</button>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default Personalizacion;