import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Redirect } from 'react-router-dom';
import Loading from '../../utils/Loading';
// import AlertConfirm from '../../utils/AlertConfirm';
import CryptoJS from 'crypto-js';
import { useParams} from 'react-router-dom';
import Header from '../Header';
import {validarSesionUsuario } from '../../utils/ValidarSesionUsuario';
import '../../styles/Modal.css';
import '../../styles/MiCuenta.css';


import axios from 'axios';
const URL = process.env.REACT_APP_URL;
const App_Token = CryptoJS.AES.encrypt(process.env.REACT_APP_LEALTAD_TOKEN, process.env.REACT_APP_LEALTAD_SECRET).toString();

const MiCuenta = ({tienda, handleAlerta}) => {

    const [loading, setLoading] = useState(false);
    const { alias } = useParams();
    const [ruta, setRuta] = useState(`/${tienda.alias}/mi-cuenta`);
    const [user, setUser] = useState(null);
    const [modalPuntosOpen, setModalPuntosOpen] = useState(false);
    const [puntos, setPuntos ] = useState(null);
    const [puntosRestantes, setPuntosRestantes ] = useState(null);
    const [canjearPuntosValue, setCanjearPuntosValue ] = useState('');
    const [nuevoCuponCreado, setNuevoCuponCread ] = useState(false);
    const [cuponCreadoCode, setCuponCreadoCode ] = useState(null);
    const [ cupones, setCupones ] = useState([]);
    // const [ openConfirmAlert, setConfirmAlert ] = useState(true);

    useEffect(()=> {
        validarUserSession();
    }, [])

    const validarUserSession = async () => {
        const userSession = JSON.parse(sessionStorage.getItem('user'));
        if(userSession === null) {
          setRuta(`/${alias}/auth/`);
        } else {
            if(userSession.hasOwnProperty('token')){
              const userSesionValida = await validarSesionUsuario(tienda.rfc, userSession.celular, userSession.token);
              if(userSesionValida){
                setRuta(`/${alias}/mi-cuenta/`);
                setUser(userSession);
                getUsuarioPuntos(userSession);
                getUsuarioCupones(userSession);
              }else {
                sessionStorage.setItem('user',null);
                setUser(null);
                setRuta(`/${alias}/auth/`);
                handleAlerta({'active': true, 'type': 'warning', 'message': 'Se ha cerrado la sesión'});
              }
            } else {
              sessionStorage.setItem('user',null);
              setUser(null);
              setRuta(`/${alias}/auth/`);
            }
        }
      }

      const getUsuarioCupones = async (userData) => {
        setLoading(true);
        const data = {
          "celular": userData.celular
        };
        try {
          const response = await axios.post(`${URL}/clientes/cupones/${tienda.rfc}`,JSON.stringify(data),
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${userData.token}`
            }
          });
          setLoading(false);
          setCupones(response.data.data);
        } catch (error) {
          setLoading(false);
        }
      }

      const getUsuarioPuntos = async (userData) => {
        setLoading(true);
        const data = {
          "clicod": userData.clicod,
          "celular": userData.celular
        };
        try {
          const response = await axios.post(`${URL}/clientes/puntos/${tienda.rfc}`,JSON.stringify(data),
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${userData.token}`
            }
          });
          setLoading(false);
          if(response.status === 200) {
            const puntosUsuario = response.data.data.puntos;
            setPuntos(puntosUsuario);
          }
        } catch (error) {
          setLoading(false);
        }
      }

      const canjearPuntos = async (event) => {
        event.preventDefault();
        const puntosInput = event.target.puntos.value;
        if(puntosInput <= puntos){
          setLoading(true);
          alert(`Deseas canjear ${puntosInput} puntos por cupón de descuento`);

          const data = {
            "clicod": user.clicod,
            "puntos": puntosInput,
            "celular": user.celular,
            "email": user.email
          };
          try {
            const response = await axios.post(`${URL}/clientes/canjear-puntos/${tienda.rfc}`,JSON.stringify(data),
            {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
              }
            });
            setLoading(false);
            const codigoCupon = response.data.data.discount_code.code;
            setCuponCreadoCode(codigoCupon);
            setNuevoCuponCread(true);
            handleClosenModalPuntos();
            getUsuarioPuntos(user);
          } catch (error) {
            if(error.response.data) {
              handleAlerta({'active': true, 'type': 'warning', 'message': error.response.data.meta.message});
            } else {
              handleAlerta({'active': true, 'type': 'warning', 'message': 'Error al canjear puntos'});
            }
            setCuponCreadoCode(null);
            setNuevoCuponCread(false);
            setLoading(false);
            getUsuarioPuntos(user);
          }
        }else {
          handleAlerta({'active': true, 'type': 'warning', 'message': 'Puntos insuficientes'});
          setLoading(false);
        }
      }

      // const confirmarCanjearPuntos = async (confirm) => {
      //   if(confirm){

      //   } else {
      //     setConfirmAlert(false);
      //   }
      // }

      const calcularPuntos = (event) => {
        const puntosInput = event.target.value;
        setCanjearPuntosValue(puntosInput);
        if(puntosInput != null && puntosInput != '') { 
          const result = puntos - puntosInput
          if(result < 0) {
            setPuntosRestantes(0);
          } else {
            setPuntosRestantes(result);
          }
        }else {
          setPuntosRestantes(null)
        }
    }

    const handleOpenModalPuntos = () => {
      setModalPuntosOpen(true);
      setCanjearPuntosValue('');
      setPuntosRestantes(null);
    }

    const handleClosenModalPuntos = () => {
      setModalPuntosOpen(false); 
      setCanjearPuntosValue('');
      setPuntosRestantes(null);
    }

    const cerrarSesion = () => {
      sessionStorage.setItem('user', null);
      setUser(null);
    }

    const cuponesList = cupones.map((cupon, index) => 
      <Grid item xs={12} sm={6} md={6} key={index}>
        <div className="cupon" onClick={() =>  navigator.clipboard.writeText(cupon.code)} title="Copiar código">
            {cupon.code}
        </div>
      </Grid>
    );

    return(
        <div className="miCuenta-container">
            <Header tienda={tienda} cerrarSesion={cerrarSesion}/>
            <Redirect to={ruta} />
            <Loading loading={loading} />
            {/* <AlertConfirm confirm={confirmarCanjearPuntos} open={openConfirmAlert} /> */}
            {
              user != null &&
              <Container component="main" maxWidth="md" className="main-container">
              <Grid container spacing={3} className="rowContainer rowUserData" >
                <Grid item xs={12} sm={12} md={6} className="flex-left">
                  <div className="boxUserData">
                    <div className="boxWelcomeTxt">
                      {/* <span >Hola,</span> */}
                    </div>
                    <div className="boxUserName">
                      <span>{user.nombre}</span>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} className="flex-right">
                  <div className="box-userData">
                    <div className="boxUserName">
                      <span>{user.celular}</span>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid container className="rowContainer">
                  <Grid item xs={12} sm={12} md={12} className="flex-center">
                      <div className="box-puntos flex-center">
                        {
                          puntos != null &&
                            <div>
                              <div className="box-txtTitlePuntos">
                                  <span className="subtitlePuntos">Mis puntos lealtad</span>
                              </div>
                              <div className="boxTxtPuntos">
                                  <span className="txtPuntos">{puntos.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                              </div>
                          </div>
                        }
                      </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} className="flex-center boxBtnCanjear">
                    {/* {
                      user.puntos > 0 && */}
                        <button className="btnCanjearPuntos" onClick={()=>{handleOpenModalPuntos()}}>Canjear Puntos</button>
                    {/* } */}
                  </Grid>
              </Grid>

              {
                    cuponCreadoCode != null &&
                    <Grid container spacing={3} className="rowContainer">
                        <Grid item xs={12} sm={12} md={12}>
                          <h1 className="title">Cupón creado </h1>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} >
                          <div className="cuponCreado" onClick={() =>  navigator.clipboard.writeText(cuponCreadoCode)} title="Copiar código">
                            {cuponCreadoCode}
                          </div>
                        </Grid>
                    </Grid>
                }

                { 
                  cupones.length > 0 &&
                      <Grid container spacing={3} className="rowContainer">
                        <Grid item xs={12} sm={12} md={12}>
                            <h1 className="title">Mis cupones</h1>
                        </Grid>
                        { cuponesList }
                      </Grid>
                }
              
              {/* Modal enter puntos starts */}
              <div className={"modal-container " + ( modalPuntosOpen ? "active" : "" )}>
                <div className="b-fade" onClick={() => {handleClosenModalPuntos()}}>

                </div>
                <form className="b-modal canjearPuntosModal" onSubmit={canjearPuntos}>
                  <div className="b-modal-aux">
                    <div className="modal-header">
                        <i className="fas fa-times" onClick={() => {handleClosenModalPuntos()}}></i>
                    </div>
                    <div className="modal-body">
                          <Grid item xs={12} sm={12} md={12} className="flex-center">
                            <div className="box-puntos flex-center">
                              <div>
                                <div className="box-txtTitlePuntos">
                                    <span className="subtitlePuntos">Mis puntos</span>
                                </div>
                                <div className="boxTxtPuntos">
                                  {
                                    puntos != null && 
                                      <span className="txtPuntos">{puntos.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>

                                  }
                                </div>
                              </div>
                            </div>
                        </Grid>
                        <p className="modal-title">Ingresa los puntos que deseas canjear por cupón</p>
                        <p className="modal-text">El valor del cupón será el equivalente a los puntos canjeados</p>
                        <div className="flex-center">
                          <div className="input-box flex-center">
                            {/* <i className="fas fa-number icon">-</i> */}
                            <input  inputMode="numeric" name="puntos" value={canjearPuntosValue} onChange={calcularPuntos}/>
                          </div>
                        </div>
                          {
                              puntosRestantes != null && 
                              <p className="modal-text flex-right">Te quedarian { puntosRestantes.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") } puntos</p>
                          }
                      </div>
                      <div className="modal-footer">
                          <button type="submit" className="btnCanjearPuntos">Canjear</button>
                      </div>
                    </div>
                </form>
              </div>
              {/* Modal confirm canjear puntos */}
                          
            </Container>
            }
        </div>
    );
}

export default MiCuenta;


