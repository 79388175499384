import '../styles/Modal.css';

const Modal = ({ validarCodigo, showModal, handleModal }) => {

  const validarCodigoForm = (event) => {
        event.preventDefault();
        validarCodigo(event.target.codigo.value);
    }

    return(
        <div className={"modal-container " + ( showModal ? "active" : "" )}>
            <div className="b-fade" onClick={() => handleModal()}>

            </div>
            <form className="b-modal" onSubmit={validarCodigoForm}>
              <div className="b-modal-aux">
                <div className="modal-header">
                    <i className="fas fa-times" onClick={() => handleModal()}></i>
                </div>
                <div className="modal-body">
                    <p className="modal-title">Ingresa el código que enviamos vía SMS a tu teléfono</p>
                    <div className="input-box">
                            <i className="fas fa-number icon">#</i>
                    <input inputMode="numeric" name="codigo"/>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="submit" className="validar-btn">Validar</button>
                </div>
                </div>
            </form>
        </div>
    );
};

export default Modal;
