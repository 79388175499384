import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { NavLink } from "react-router-dom";
import Header from './Header'
import '../styles/Home.css';

const Home = ({ tienda }) => { 
  console.log(tienda)
    return(
      <div className="home-container">
        <Header tienda={tienda}/>
            <Container component="main" maxWidth="md">
            <Grid container className="box-container">
              <Grid item xs={12} sm={12} md={12} >
                {
                  tienda.titulo_1.activo && 
                  <h1 className="titulo1">{tienda.titulo_1.contenido}</h1>
                }
                {
                  tienda.titulo_2.activo && 
                  <h3 className="titulo2">{tienda.titulo_2.contenido}</h3>
                }
                {
                  tienda.banner.activo && 
                  <img src={tienda.banner.url} alt="banner" className="banner"/>
                }
                {
                  tienda.texto_1.activo && 
                  <p className="texto1">{tienda.texto_1.contenido}</p>
                }
              </Grid>
              <Grid item xs={12} sm={12} md={12} >
                <NavLink to={`/${tienda.alias}/registro`}>
                  <button className="registrar-btn">Continuar</button>
                </NavLink>
              </Grid>

            </Grid>
          </Container>
        </div>
    );
};

export default Home;

