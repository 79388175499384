import React, {useState, useEffect} from 'react';
import Container from '@material-ui/core/Container';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Redirect } from 'react-router-dom';
import Login from './Login';
import Personalizacion from './Personalizacion';
import EditTerminosYCondiciones from './EditTerminosYCondiciones';
import EditAvisoDePrivacidad from './EditAvisoDePrivacidad';
import Header from '../Header';
import SideBar from '../../utils/SideBar';
import '../../styles/Admin.css';
import Loading from '../../utils/Loading';
import { validarSesion } from '../../utils/ValidarSesion';
import CryptoJS from 'crypto-js';
import { useParams } from 'react-router-dom';
import axios from 'axios';


const URL = process.env.REACT_APP_URL;
const App_Token = CryptoJS.AES.encrypt(process.env.REACT_APP_LEALTAD_TOKEN, process.env.REACT_APP_LEALTAD_SECRET).toString();

const Admin = ({tienda, handleAlerta, updateTienda}) => {

    const [admin, setAdmin] = useState(null);
    const [ruta, setRuta] = useState(window.location.pathname);
    const [loading, setLoading] = useState(false);
    const { alias } = useParams();
    const [sideBarOpen, setSideBarOpen] = useState(false);


    useEffect(() => {
      validarSesionAdmin();
    },[])

    const validarSesionAdmin = async () => {
      console.log('Validacndo sesión')
      const sesionAdmin = JSON.parse(sessionStorage.getItem('admin'));
      console.log('sesionAdmin: ',sesionAdmin)
      if(sesionAdmin === null) {
        setRuta(`/${alias}/admin/`);
      } else {
          if(sesionAdmin.hasOwnProperty('token')){
            const sesionValida = await validarSesion(sesionAdmin.username, tienda.rfc, sesionAdmin.token);
            if(sesionValida){
              setRuta(`/${alias}/admin/personalizacion`);
              setAdmin(sesionAdmin);
            }else {
              sessionStorage.setItem('admin',null);
              setAdmin(null);
              setRuta(`/${alias}/admin/`);
              handleAlerta({'active': true, 'type': 'warning', 'message': 'Se ha cerrado la sesión'});
            }
          } else {
            sessionStorage.setItem('admin',null);
            setAdmin(null);
            setRuta(`/${alias}/admin/`);

          }
      }
    }

    const login = async (event) => {
      const data = {
        "username": event.target.username.value,
        "password": event.target.password.value
      };
      setLoading(true);
      axios.post(`${URL}/tiendas/${tienda.rfc}/login`,JSON.stringify(data),
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'App_Token': App_Token
        }
      }).then(( response ) => {
        setLoading(false);
        if(response.status === 200 ) {
            sessionStorage.setItem('admin', JSON.stringify(response.data.data));
            setAdmin(response.data.data);
            setRuta(`/${alias}/admin/personalizacion`);
        }else {
          handleAlerta({'active': true, 'type': 'warning', 'message': 'No autorizado'});
        }
      }).catch((error)=>{
        setLoading(false);
        handleAlerta({'active': true, 'type': 'warning', 'message': error.response.data.meta.message})
      })
  }

  const cerrarSesion = () => {
    sessionStorage.setItem('admin',null);
    setAdmin(null);
    setRuta(`/${alias}/admin/`);
  }

  const configurarTienda = async () => {
    setLoading(true);
    const data = {
        'logo': '',
        'color': '',
        'alias': tienda.alias,
        'rfc': tienda.rfc,
        'tienda_configurada': true,
        'banner': {
            'activo': false,
            'url': ''
        },
        'titulo_1': {
            'activo': false,
            'contenido': ''
        },
        'titulo_2': {
            'activo': false,
            'contenido': ''
        },
        'texto_1': {
            'activo': false,
            'contenido': ''
        },
        'registro_codigo_postal': {
            'activo': true
        },
        'registro_fecha_nacimiento': {
            'activo': true
        },
        'thankyou_page_link': {
            'activo': false,
            'url': ''
        },
        'thankyou_page_contenido': {
            'titulo_1': {
                'activo': true,
                'contenido': ''
            },
            'titulo_2': {
                'activo': true,
                'contenido': ''
            },
            'banner': {
                'activo': true,
                'url': ''
            },
            
        },
        'aviso_de_privacidad': {
          'activo': true,
          'contenido': ''
        },
          'terminos_y_condiciones': {
            'activo': true,
            'contenido': ''
        }
    };

    // send data to firebase
    axios.post(`${URL}/tiendas/${data.rfc}/update`,JSON.stringify(data),{
        headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "username": admin.username,
        'App_Token': App_Token,
        'Authorization': `Bearer ${admin.token}`
        }
    }).then((response) => {
        setLoading(false);
        handleAlerta({'active': true, 'type': 'success', 'message': 'Guardado'});
        updateTienda();
        validarSesionAdmin();
    }).catch((error)=>{
        setLoading(false);
        updateTienda();
        validarSesionAdmin();
    })
}

    return(
      <Container component="main" maxWidth="lg">
        <Loading loading={loading} />
        <div className="admin-container">

            <Header admin={admin} tienda={tienda} toggleSideBar={()=> setSideBarOpen(!sideBarOpen)}/>

            
            <Redirect to={ruta} />

             {
                admin == null && 
                <Route
                  exact
                  path="/:alias/admin/"
                  render={(props) => (
                    <Login {...props} tienda={tienda} handleAlerta={handleAlerta} login={(event) => login(event) }/>
                  )}
                />
              }
              {
                admin != null &&
                  <SideBar sideBarOpen={sideBarOpen} tienda={tienda} cerrarSesion={cerrarSesion} closeSideBar={()=> setSideBarOpen(false)}/>
              }

              {
                admin != null && 
                  tienda.tienda_configurada &&
                  <div className="view-container">
                    <Route
                      exact
                      path="/:alias/admin/personalizacion"
                      render={(props) => (
                        <Personalizacion admin={admin} {...props} tienda={tienda} handleAlerta={handleAlerta} updateTienda={()=> updateTienda()} validarSesionAdmin={()=> validarSesionAdmin()} />
                      )}
                    />
                    <Route
                      exact
                      path="/:alias/admin/terminos-y-condiciones"
                      render={(props) => (
                        <EditTerminosYCondiciones admin={admin} {...props} tienda={tienda} handleAlerta={handleAlerta} updateTienda={()=> updateTienda()} validarSesionAdmin={()=> validarSesionAdmin()} />
                      )}
                    />

                    <Route
                      exact
                      path="/:alias/admin/aviso-de-privacidad"
                      render={(props) => (
                        <EditAvisoDePrivacidad admin={admin} {...props} tienda={tienda} handleAlerta={handleAlerta} updateTienda={()=> updateTienda()} validarSesionAdmin={()=> validarSesionAdmin()} />
                      )}
                    />
                </div>
              }
              {
                admin != null && 
                  tienda.tienda_configurada === false &&
                  <div className="view-container">
                    <h1>Tienda no configurada</h1>
                    <button className="configBtn" onClick={() => configurarTienda()}>Configurar tienda</button>
                  </div>
              }
        </div>
      </Container>
    );
}

export default Admin;