
const Loading = ({loading}) => {
    return(
        <div className={"loading-container " + (loading ? "active" : "" )}>
            <div className="b-loading">
                <img src="/loading-icon.gif" alt="loading-icon" />
            </div>
        </div>
    );
};

export default Loading;
