import { NavLink } from 'react-router-dom';
import '../styles/SideBar.css';

const SideBar = ({sideBarOpen, tienda, cerrarSesion, closeSideBar}) => {

    return(
        <div className={"sidebar-container " + (sideBarOpen ? 'open' : '')}>
            <div className={"b-fade " + (sideBarOpen ? 'open' : '')} onClick={() => closeSideBar()}></div>
            <div className="b-menu">
                <div className="b-option">
                    <NavLink to={`/${tienda.alias}/admin/personalizacion`}>
                        <i className="fa fa-cog"></i>
                        <span>Personalización</span>
                    </NavLink>
                </div>
                <div className="b-option">
                    <NavLink to={`/${tienda.alias}/admin/terminos-y-condiciones`}>
                        <i className="fa fa-list-alt"></i>
                        <span>Términos y condiciones</span>
                    </NavLink>
                </div>
                <div className="b-option">
                    <NavLink to={`/${tienda.alias}/admin/aviso-de-privacidad`}>
                        <i className="fa fa-list-alt"></i>
                        <span>Aviso de privacidad</span>
                    </NavLink>
                </div>
                <div className="b-option" onClick={()=> cerrarSesion()}>
                    <button className="btn-logOut">
                        <i className="fa fa-sign-out-alt"></i>
                        <span>Cerrar Sesión</span>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default SideBar;