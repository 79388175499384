import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Header from './Header'
import marked from 'marked';
import {NavLink} from 'react-router-dom'
import '../styles/Institucionales.css';

const AvisoDePrivacidad = ({tienda}) => {

  const avisoDePrivacidad = marked(tienda.aviso_de_privacidad.contenido);
  
    return(
        <div className="institucionales-container">
            <Header tienda={tienda}/>
            <Container component="main" maxWidth="md">
                <Grid container className="box-container">
                    <Grid item xs={12} sm={12} md={12} className="b-title">
                        <NavLink to={`/${tienda.alias}/registro`} className="backLink">
                            <i className="fa fa-arrow-left"></i>
                        </NavLink>
                        <h1 className="title">Aviso de privacidad</h1>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} >
                            <div dangerouslySetInnerHTML={{__html: avisoDePrivacidad}}></div>                        
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default AvisoDePrivacidad;