import { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Header from './Header'
import '../styles/Home.css';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const ThankYouPage = ({ tienda }) => { 
  const { celular } = useParams();
  const [googlePass, setGooglePass] = useState(null);
  const [applePass, setApplePass] = useState(null);

  useEffect(() => {
    const getWalletPasses = async () => {
      console.log('celular', celular);
      console.log('tienda', tienda);
      try {
        const response = await axios.post(`https://loyalty-wallets-api.herokuapp.com/api/v1/wallets/create/${tienda.rfc}/${celular}`,
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            }
          });
  
          console.log('response', response);
          setGooglePass(response.data.googlePassUrl);
          setApplePass(response.data.applePassUrl);
      } catch (error) {
        console.log('Error al obtener passes', error);
      }
    };

    if (celular && tienda) {
      getWalletPasses();
    }
  }, []);
    return(
      <div className="home-container">
        <Header tienda={tienda}/>
            <Container component="main" maxWidth="md">
            <Grid container className="box-container center">
              <Grid item xs={12} sm={12} md={12} >
                {
                  tienda.thankyou_page_contenido.titulo_1.activo && 
                  <h1 className="titulo1">{tienda.thankyou_page_contenido.titulo_1.contenido}</h1>
                }
                {
                  tienda.thankyou_page_contenido.titulo_2.activo && 
                  <h3 className="titulo2">{tienda.thankyou_page_contenido.titulo_2.contenido}</h3>
                }
                {
                  tienda.rfc === 'YYY010101YYY' && googlePass &&
                  <a href={googlePass}><button className="wallet-btns google">Add to Google wallet</button></a>
                }
                {
                  tienda.rfc === 'YYY010101YYY' && applePass &&
                  <a href={applePass}><button className="wallet-btns apple">Add to Apple wallet</button></a>
                }
                {
                  tienda.thankyou_page_contenido.banner.activo && 
                  <img src={tienda.thankyou_page_contenido.banner.url} alt="banner" className="banner"/>
                }
              </Grid>
            </Grid>
          </Container>
        </div>
    );
};

export default ThankYouPage;