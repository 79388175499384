import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Redirect } from 'react-router-dom';
import Loading from '../../utils/Loading';
import CryptoJS from 'crypto-js';
import "react-markdown-editor-lite/lib/index.css";
import '../../styles/Personalizacion.css';

import axios from 'axios';
const URL = process.env.REACT_APP_URL;
const App_Token = CryptoJS.AES.encrypt(process.env.REACT_APP_LEALTAD_TOKEN, process.env.REACT_APP_LEALTAD_SECRET).toString();

const Personalizacion = ({admin, tienda, handleAlerta, updateTienda, validarSesionAdmin}) => {

    const [loading, setLoading] = useState(false);
    const [logo, setLogo] = useState(tienda.logo);
    const [banner, setBanner] = useState(tienda?.banner?.url);
    const [bannerThankyou, setBannerThankyou] = useState(null);
    const [ruta, setRuta] = useState(`/${tienda.alias}/admin/personalizacion`);

    useEffect(()=> {
        console.log('aqui')
        validarSesionAdmin();
        if(tienda.hasOwnProperty('thankyou_page_contenido')){
            setBannerThankyou(tienda.thankyou_page_contenido.banner.url);
        }
        // console.log('tienda: ', tienda)
    }, [])

    const handleLogoChange = (event) => {
        if(event.target.files[0] != undefined){
            setLogo(
                window.URL.createObjectURL(event.target.files[0])
            )
        }else{
            setLogo(
                null
            )
        }
    }

    const handleBannerChange = (event) => {
        if(event.target.files[0] != undefined){
            setBanner(
                window.URL.createObjectURL(event.target.files[0])
            )
        }else{
            setBanner(
                null
            )
        }
    }

    const handleBannerThankyouChange = (event) => {
        if(event.target.files[0] != undefined){
            setBannerThankyou(
                window.URL.createObjectURL(event.target.files[0])
            )
        }else{
            setBannerThankyou(
                null
            )
        }
    }

    const uploadFile = async file => {
        let formData = new FormData();
        formData.append("image", file);
        try {
            const response = await axios.post(`${URL}/tiendas/upload-file`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'App_Token': App_Token,
                }
            });
            console.log('response.data: ', response.data)

            return response.data;
        } catch (error) {
            console.log('response.error: ', error)

            return false;
        }
    }

    const guardarCambiosConfiguracion = async (event) => {
        event.preventDefault();
        setLoading(true);
        const data = {
            'logo': event.target.logo.value,
            'color': event.target.color.value,
            'alias': tienda.alias,
            'rfc': tienda.rfc,
            'tienda_configurada': true,
            'canjeo_de_puntos_activado': event.target.canjeo_de_puntos_activado.checked,
            'banner': {
                'activo': event.target.banner_activo.checked,
                'url': event.target.banner_url.value
            },
            'titulo_1': {
                'activo': event.target.titulo_1_activo.checked,
                'contenido': event.target.titulo_1_contenido.value
            },
            'titulo_2': {
                'activo': event.target.titulo_2_activo.checked,
                'contenido': event.target.titulo_2_contenido.value
            },
            'texto_1': {
                'activo': event.target.texto_1_activo.checked,
                'contenido': event.target.texto_1_contenido.value
            },
            'registro_codigo_postal': {
                'activo': event.target.registro_codigo_postal_activo.checked
            },
            'registro_fecha_nacimiento': {
                'activo': event.target.registro_fecha_nacimiento_activo.checked
            },
            'thankyou_page_link': {
                'activo': event.target.thankyou_page_link_activo.checked,
                'url': event.target.thankyou_page_link_url.value
            },
            'thankyou_page_contenido': {
                'titulo_1': {
                    'activo': event.target.thankyou_page_contenido_titulo_1_activo.checked,
                    'contenido': event.target.thankyou_page_contenido_titulo_1_contenido.value
                },
                'titulo_2': {
                    'activo': event.target.thankyou_page_contenido_titulo_2_activo.checked,
                    'contenido': event.target.thankyou_page_contenido_titulo_2_contenido.value
                },
                'banner': {
                    'activo': event.target.thankyou_page_contenido_banner_activo.checked,
                    'url': event.target.thankyou_page_contenido_banner_url.value
                },
                
            },
            'aviso_de_privacidad': tienda.aviso_de_privacidad,
            'terminos_y_condiciones': tienda.terminos_y_condiciones
        };

        // //Check logo image
        if(data.logo == ''){
            data.logo = tienda.logo;
        }else {
            const responseUploadFile = await uploadFile(event.target.logo.files[0]);
            console.log('responseUploadFile: ', responseUploadFile)
            if(responseUploadFile.meta.ok){
                data.logo =  responseUploadFile.data.mediaLink;
            }else{
                alert("Error al guardar imagen logo");
            }
        }
        // //Check banner image
        if(data.banner.url == ''){
            data.banner.url = tienda.banner.url;
        }else {
            const responseUploadFile = await uploadFile(event.target.banner_url.files[0]);
            if(responseUploadFile.meta.ok){
                data.banner.url =  responseUploadFile.data.mediaLink;
            }else{
                alert("Error al guardar imagen banner");
            }
        }

        if(data.thankyou_page_contenido.banner.url == ''){
            data.thankyou_page_contenido.banner.url = tienda.thankyou_page_contenido.banner.url;
        }else {
            const responseUploadFile = await uploadFile(event.target.thankyou_page_contenido_banner_url.files[0]);
            if(responseUploadFile.meta.ok){
                data.thankyou_page_contenido.banner.url =  responseUploadFile.data.mediaLink;
            }else{
                alert("Error al guardar banner contenido thankyou page");
            }
        }
        //send data to firebase
        axios.post(`${URL}/tiendas/${tienda.rfc}/update`,JSON.stringify(data),{
            headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "username": admin.username,
            'App_Token': App_Token,
            'Authorization': `Bearer ${admin.token}`
            }
        }).then((response) => {
            setLoading(false);
            handleAlerta({'active': true, 'type': 'success', 'message': 'Guardado'});
            updateTienda();
            validarSesionAdmin();
        }).catch((error)=>{
            setLoading(false);
            updateTienda();
            validarSesionAdmin();
        })
    }

    const activarCampoThanKyouPageLInk = (event) => {
        const data = {
            "values":{
                "campo": 'thankyou_page_link',
                "tipoCampo": "activo",
                "valor": event.target.checked
            }
        };

        setLoading(true);
        axios.post(`${URL}/tiendas/${tienda.rfc}/update-field`,JSON.stringify(data),{
            headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "username": admin.username,
            'App_Token': App_Token,
            'Authorization': `Bearer ${admin.token}`
            }
        }).then((response) => {
            setLoading(false);
            updateTienda();
        }).catch((error)=>{
            setLoading(false);
            if(error.response.status === 401) {
                validarSesionAdmin();
            }
        })
    }

    // if(tienda.configurada !== true) {

    //     return (
    //         <h1>No Configurada</h1>
    //     )

    // }

    return(
        <div className="personalizacion-container">
            <Loading loading={loading} />
            <Redirect to={ruta} />
            {/* <SideBar tienda={tienda} /> */}
            <h1 className="title">Personalización</h1>
            <Container component="main" maxWidth="md">
                <Grid container className="box-container">
                    <Grid item xs={12} sm={12} md={12} >
                    <div  className="formulario">
                    <form onSubmit={guardarCambiosConfiguracion}>
                        <Grid container className="box-formulario">
                                <h2 className="title">Página de inicio</h2>
                                {
                                    tienda.hasOwnProperty('logo') && 
                                    <Grid item xs={12} sm={12} md={12} className="form-item">
                                        <div className="box-name">
                                        <span className="field-name">Logo</span>
                                        </div>
                                        <div className="box-input">
                                            <input type="file"  className="form-input" placeholder="" name="logo"  onChange={handleLogoChange}/>
                                        </div>
                                        <img src={logo} className="logo"/>
                                    </Grid>
                                }
                                {
                                    tienda.hasOwnProperty('color') && 
                                    <Grid item xs={12} sm={12} md={12} className="form-item">
                                        <div className="box-name">
                                        <span className="field-name">Color </span>
                                        </div>
                                        <div className="box-input">
                                            <input type="color"  defaultValue={tienda.color} className="form-input" placeholder="" name="color" />
                                        </div>
                                    </Grid>
                                }
                                {
                                    tienda.hasOwnProperty('titulo_1') && 
                                    <Grid item xs={12} sm={12} md={12} className="form-item">
                                    <div className="box-name">
                                        <span className="field-name">Título 1</span>
                                        <label className="switch">
                                        {/* onChange={updateCampoActivo} */}
                                            <input type="checkbox" name="titulo_1_activo" defaultChecked={tienda.titulo_1.activo} />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                    <div className="box-input">
                                        <input defaultValue={tienda.titulo_1.contenido} type="text" inputMode="latin-name" className="form-input" name="titulo_1_contenido" />
                                    </div>
                                </Grid>
                                
                                }
                                
                                {
                                    tienda.hasOwnProperty('banner') && 
                                    <Grid item xs={12} sm={12} md={12} className="form-item">
                                    <div className="box-name">
                                        <span className="field-name">Banner</span>
                                        <label className="switch">
                                            <input type="checkbox" defaultChecked={tienda.banner.activo} name="banner_activo" />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                    {/* <form onSubmit={updateBanner}> */}
                                        <div className="box-input">
                                            <input type="file"  className="form-input" placeholder="" name="banner_url" onChange={handleBannerChange} />
                                        </div>
                                        <div className="box-banner">
                                            <img src={banner} className="banner"/>
                                        </div>
                                        {/* <button className="btn-upload" type="submit" >Upload Banner</button> */}
                                    {/* </form> */}
                                </Grid>
                                
                                }
                                
                                {
                                    tienda.hasOwnProperty('titulo_2') && 
                                    <Grid item xs={12} sm={12} md={12} className="form-item">
                                    <div className="box-name">
                                        <span className="field-name">Título 2</span>
                                        <label className="switch">
                                            <input type="checkbox" name="titulo_2_activo" defaultChecked={tienda.titulo_2.activo} />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                    <div className="box-input">
                                        <input defaultValue={tienda.titulo_2.contenido} name="titulo_2_contenido" type="text" inputMode="latin-name" className="form-input"/>
                                    </div>
                                </Grid>
                                
                                }
                                
                                {
                                    tienda.hasOwnProperty('texto_1') && 
                                    <Grid item xs={12} sm={12} md={12} className="form-item">
                                    <div className="box-name">
                                        <span className="field-name">Texto 1</span>
                                        <label className="switch">
                                            <input type="checkbox" name="texto_1_activo" defaultChecked={tienda.texto_1.activo} />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                    <div className="box-input">
                                    {/* onFocus={(event)=> setInputValue(event.target.value)} onBlur={updateContenidoConfiguracion} */}
                                        <textarea rows="5" cols="50" type="text" inputMode="latin-name"  className="form-area" name="texto_1_contenido" defaultValue={tienda.texto_1.contenido} ></textarea>
                                    </div>
                                </Grid>    
                                
                                }
                        </Grid>
                        <Grid container className="box-formulario">
                            <h2 className="title">Campos de formulario</h2>
                            <Grid item xs={12} sm={12} md={12} >
                            </Grid>
                            {
                            tienda.hasOwnProperty('registro_codigo_postal') && 
                                <Grid item xs={12} sm={12} md={12} className="form-item">
                                    <div className="box-name">
                                        <span className="field-name">Código Postal</span>
                                        <label className="switch">
                                            <input type="checkbox"  name="registro_codigo_postal_activo" defaultChecked={tienda.registro_codigo_postal.activo} />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </Grid>    
                            }
                            {
                            tienda.hasOwnProperty('registro_fecha_nacimiento') && 
                                <Grid item xs={12} sm={12} md={12} className="form-item">
                                    <div className="box-name">
                                        <span className="field-name">Fecha de nacimiento</span>
                                        <label className="switch">
                                            <input type="checkbox" name="registro_fecha_nacimiento_activo" defaultChecked={tienda.registro_fecha_nacimiento.activo} />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </Grid>
                            }
                        </Grid>

                        <Grid container className="box-formulario">
                            <h2 className="title">Página de agradecimiento</h2>
                            <Grid item xs={12} sm={12} md={12} >
                            </Grid>
                            {
                            tienda.hasOwnProperty('thankyou_page_link') && 
                                <Grid item xs={12} sm={12} md={12} className="form-item">
                                    <div className="box-name">
                                        <h3 className="title">Link</h3>
                                        <label className="switch">
                                            <input type="checkbox"  name="thankyou_page_link_activo" defaultChecked={tienda.thankyou_page_link.activo} onChange={(event)=> activarCampoThanKyouPageLInk(event)}/>
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                    <div className="box-input">
                                        <input defaultValue={tienda.thankyou_page_link.url} name="thankyou_page_link_url" type="text" inputMode="latin-name" className="form-input"/>
                                    </div>
                                </Grid>    
                            }
                            {
                            tienda.hasOwnProperty('thankyou_page_contenido') &&
                             tienda.hasOwnProperty('thankyou_page_link') && 
                              tienda.thankyou_page_link.activo != true &&
                                <Grid item xs={12} sm={12} md={12} className="form-item">
                                    <div className="box-name">
                                        <h3 className="title">Contenido</h3>
                                    </div>
                                </Grid>    
                            }
                            {
                            tienda.hasOwnProperty('thankyou_page_contenido') &&
                            //  tienda.hasOwnProperty('thankyou_page_link') && 
                            //   tienda.thankyou_page_link.activo != true &&
                                tienda.thankyou_page_contenido.hasOwnProperty('titulo_1') && 
                                <Grid item xs={12} sm={12} md={12} className={"form-item form-contenido " + (tienda.thankyou_page_link.activo ? 'hide' : '')}>
                                    <div className="box-name">
                                        <span className="field-name">Título 1</span>
                                        <label className="switch">
                                            <input type="checkbox"  name="thankyou_page_contenido_titulo_1_activo" defaultChecked={tienda.thankyou_page_contenido.titulo_1.activo} />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                    <div className="box-input">
                                        <input defaultValue={tienda.thankyou_page_contenido.titulo_1.contenido} name="thankyou_page_contenido_titulo_1_contenido" type="text" inputMode="latin-name" className="form-input"/>
                                    </div>
                                </Grid>    
                            }
                            {
                            tienda.hasOwnProperty('thankyou_page_contenido') &&
                            //  tienda.hasOwnProperty('thankyou_page_link') && 
                            //   tienda.thankyou_page_link.activo != true &&
                                tienda.thankyou_page_contenido.hasOwnProperty('titulo_2') && 
                                <Grid item xs={12} sm={12} md={12} className={"form-item form-contenido " + (tienda.thankyou_page_link.activo ? 'hide' : '')}>
                                    <div className="box-name">
                                        <span className="field-name">Título 2</span>
                                        <label className="switch">
                                            <input type="checkbox"  name="thankyou_page_contenido_titulo_2_activo" defaultChecked={tienda.thankyou_page_contenido.titulo_2.activo} />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                    <div className="box-input">
                                        <input defaultValue={tienda.thankyou_page_contenido.titulo_2.contenido} name="thankyou_page_contenido_titulo_2_contenido" type="text" inputMode="latin-name" className="form-input"/>
                                    </div>
                                </Grid>    
                            }
                            {
                            tienda.hasOwnProperty('thankyou_page_contenido') &&
                            //  tienda.hasOwnProperty('thankyou_page_link') && 
                            //   tienda.thankyou_page_link.activo != true &&
                                tienda.thankyou_page_contenido.hasOwnProperty('banner') && 
                                <Grid item xs={12} sm={12} md={12} className={"form-item form-contenido " + (tienda.thankyou_page_link.activo ? 'hide' : '')}>
                                    <div className="box-name">
                                        <span className="field-name">Banner</span>
                                        <label className="switch">
                                            <input type="checkbox"  name="thankyou_page_contenido_banner_activo" defaultChecked={tienda.thankyou_page_contenido.banner.activo} />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                    <div className="box-input">
                                            <input type="file"  className="form-input" placeholder="" name="thankyou_page_contenido_banner_url" onChange={handleBannerThankyouChange} />
                                        </div>
                                        <div className="box-banner">
                                            <img src={bannerThankyou} className="banner"/>
                                        </div>
                                </Grid>    
                            }
                            
                        </Grid>

                            {
                                tienda.hasOwnProperty('canjeo_de_puntos_activado') &&
                                    <Grid container className="box-formulario">
                                        <h2 className="title">Puntos cliente</h2>
                                        <Grid item xs={12} sm={12} md={12} className="form-item">
                                            <div className="box-name">
                                                <h3 className="title">Habilitar canjeo de puntos por cupón</h3>
                                                <label className="switch">
                                                    <input type="checkbox"  name="canjeo_de_puntos_activado" defaultChecked={tienda.canjeo_de_puntos_activado} />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </Grid>  
                                    </Grid>
                            }
                            
                        <Grid container >
                            <Grid item xs={12} sm={12} md={12} className="box-submit">
                                <button type="submit" className="btn-submit">Guardar Cambios</button>
                            </Grid>
                        </Grid>
                            </form>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default Personalizacion;


