import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

const PageNotFound = ({alias}) => {
    return (
        <div className="pageNotFound-container">
            <Container maxWidth="md" >
                <Grid container spacing={3}>
                    <Grid item md={12}>
                        <h1 style={({"textAlign": "center"})}>404 </h1>
                        <h2 style={({"textAlign": "center"})}>Tienda "{ `${alias}`}" no encontrada</h2>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default PageNotFound;