import { initializeApp } from "firebase/app";
import { getFirestore, } from "firebase/firestore";

initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: 'lealtad-a7aa1.appspot.com',
  });

  const db = getFirestore();

export default db;

