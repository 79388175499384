import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Redirect } from 'react-router-dom';
import Loading from '../../utils/Loading';
import CryptoJS from 'crypto-js';
import { useParams} from 'react-router-dom';
import Header from '../Header';
import ModalComponente from '../../utils/Modal';
import '../../styles/Auth.css';

import axios from 'axios';
const URL = process.env.REACT_APP_URL;
const App_Token = CryptoJS.AES.encrypt(process.env.REACT_APP_LEALTAD_TOKEN, process.env.REACT_APP_LEALTAD_SECRET).toString();

const Auth = ({tienda, handleAlerta}) => {

    const [loading, setLoading] = useState(false);
    const { alias } = useParams();
    const [ruta, setRuta] = useState(`/${tienda.alias}/auth`);
    const [ celularNumber, setcelularNumber] = useState(false);
    const [ modal, setModal] = useState(false);

    const handleModal = () => {
        setModal(!modal);
      }

    const validarCelular = (event) => {
        event.preventDefault();
        const celular = event.target.celular.value;
        if(celular.length === 10) {
            const isNumber = /^\d+$/.test(celular);
            if(isNumber){
                validarUsuario(celular);
            } else {
                handleAlerta({'active': true, 'type': 'warning', 'message': 'Número de celular no válido'})
            }
        } else{
            handleAlerta({'active': true, 'type': 'warning', 'message': 'Ingresa los 10 dígitos de tu celular'})
        }
    }

    const validarUsuario = async (celular) => {
        setLoading(true);
        const data = {
            "celular": celular
          };
          try {
            const response = await axios.post(`${URL}/clientes/validar-usuario/${tienda.rfc}`,JSON.stringify(data),
            {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              }
            });
          setLoading(false);
          setModal(!modal);
          setcelularNumber(celular);
          } catch (error) {
            setLoading(false);
            if(error.response) {
              handleAlerta({'active': true, 'type': 'warning', 'message': error.response.data.meta.message})
            } else {
              handleAlerta({'active': true, 'type': 'warning', 'message': 'Error en conexion'})
            }
            setModal(false);
            setcelularNumber(null);
          }
    }

    const validarCodigo = async (codigo) => {
        setLoading(true);
        if(codigo === "") {
          handleAlerta({'active': true, 'type': 'warning', 'message': 'Ingresar código'})
        } else {
          try {
            const data = {
              "celular": celularNumber,
              "codigo": codigo
            };
            const response = await axios.post(`${URL}/clientes/auth/${tienda.rfc}`,JSON.stringify(data),
            {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              }
            });
            setLoading(false);
            if(response.data.meta.ok){
              sessionStorage.setItem('user', JSON.stringify(response.data.data))
              setRuta(`/${alias}/mi-cuenta`);
            }else {
              handleAlerta({'active': true, 'type': 'warning', 'message': 'No autorizado'})
            }
          } catch (error) {
            setLoading(false);
            handleAlerta({'active': true, 'type': 'warning', 'message': 'No autorizado'})
          }
        }
      }



    return(
        <div className="auth-container">
        <Loading loading={loading} />
        <Header tienda={tienda}/>
        <Redirect to={ruta} />
        <ModalComponente handleModal={handleModal} showModal={modal} validarCodigo={validarCodigo}/>
            <Container component="main" maxWidth="md">
            <Grid container className="row-container">
              <Grid item xs={12} sm={12} md={12} >
                <h1 className="title flex-center">Iniciar Sesión</h1>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} >
                <form onSubmit={validarCelular} className="flex-center">
                <div className="box-login">
                    <p className="text">Ingresa tu número de celular</p>
                    <div className="flex-center">
                        <input inputMode="tel" className="input" name="celular"/>
                    </div>
                    <button className="btn">Ingresar</button>
                </div>
                </form>
            </Grid>
          </Container>
        </div>
    );
}

export default Auth;