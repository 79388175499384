import React from 'react';
import { BrowserRouter as Router, Route} from "react-router-dom";
import { Redirect } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import './App.css'
import Tienda from './components/Tienda';

const App = () => {
  const alias = localStorage.getItem('alias');
  const ruta = `/${alias}`

  return(
    <div className="app-container">
      <BrowserRouter>
        <Route  exact path="/">
          <Redirect to={ruta} /> 
        </Route>
        <Route
            path="/:alias"
            render={(props) => (
              <Tienda {...props}/>
            )}
          />
      </BrowserRouter>
    </div>
  );
};

export default App;
