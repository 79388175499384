import React from 'react';
import '../styles/Header.css';
import { NavLink } from "react-router-dom";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { useLocation } from 'react-router-dom';

const Header = ({admin, tienda, toggleSideBar, cerrarSesion}) => {

    const location = useLocation();

    

    return (
        <div className="header-container">
            <Container maxWidth="md" >
                <Grid container className="box-container">
                    <Grid item xs={6} md={6} className="b-header">
                        <NavLink to={`/${tienda.alias}`} className="header-logo">
                            <img src={tienda.logo} alt="logo-tienda"/>
                        </NavLink>
                    </Grid>

                    {
                        admin != null &&
                        <Grid item xs={6} md={6} className="b-link">
                            <i className="fa fa-bars" onClick={() => toggleSideBar()}></i>
                         </Grid>
                    }
                    {
                        tienda.hasOwnProperty('canjeo_de_puntos_activado') &&
                            tienda.canjeo_de_puntos_activado &&
                                !location.pathname.includes('admin') &&
                                !location.pathname.includes('mi-cuenta') &&
                                !location.pathname.includes('auth') &&
                                !location.pathname.includes('registro') &&
                                !location.pathname.includes('terminos-y-condiciones') &&
                                !location.pathname.includes('aviso-de-privacidad') &&
                                    <Grid item xs={6} md={6} className="b-link">
                                        <NavLink to={`/${tienda.alias}/mi-cuenta`} className="headerLink">
                                            <i className="far fa-user-circle"></i>  
                                            <span > Mi cuenta</span>
                                        </NavLink>
                                    </Grid>
                    }
                    {
                        tienda.hasOwnProperty('canjeo_de_puntos_activado') &&
                        tienda.canjeo_de_puntos_activado &&
                            !location.pathname.includes('admin') &&
                                location.pathname.includes('mi-cuenta') &&
                                    !location.pathname.includes('auth') &&
                                        <Grid item xs={6} md={6} className="b-link">
                                            <NavLink to={`/${tienda.alias}/auth`} className="headerLink" onClick={() => {cerrarSesion()}}>
                                                <i className="fas fa-sign-out-alt"></i>  
                                                <span> Cerrar Sesión</span>
                                            </NavLink>
                                        </Grid>
                        }
                </Grid>
            </Container>
        </div>
    );
}

export default Header;
