import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import marked from 'marked';
import Editor from "react-markdown-editor-lite";
import ReactMarkdown from "react-markdown";
import "react-markdown-editor-lite/lib/index.css";
import CryptoJS from 'crypto-js';
import '../../styles/Editor.css'

import axios from 'axios';
const URL = process.env.REACT_APP_URL;
const App_Token = CryptoJS.AES.encrypt(process.env.REACT_APP_LEALTAD_TOKEN, process.env.REACT_APP_LEALTAD_SECRET).toString();



const AvisoDePrivacidad = ({admin, tienda, handleAlerta, updateTienda, validarSesionAdmin}) => {

    const mdEditor = React.useRef(null);
    const [ terminosYCondiciones, setTerminosYCondiciones ] = useState(tienda.aviso_de_privacidad.contenido);
    const [ markdown, setMarkdown ] = useState(marked(tienda.aviso_de_privacidad.contenido));
    const [loading, setLoading] = useState(false);


    useEffect(() =>{
        validarSesionAdmin();
    },[])

    const updateAvisoDePrivacidad = (event) => {
        event.preventDefault();
        if (mdEditor.current) {
            const terminosYCondicionesValue = mdEditor.current.getMdValue();
                const data = {
                    "values":{
                        "campo": 'aviso_de_privacidad',
                        "tipoCampo": "contenido",
                        "valor": terminosYCondicionesValue
                    }
                };
                setLoading(true);
                axios.post(`${URL}/tiendas/${tienda.rfc}/update-field`,JSON.stringify(data),{
                    headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "username": admin.username,
                    'App_Token': App_Token,
                    'Authorization': `Bearer ${admin.token}`
                    }
                }).then((response) => {
                    setLoading(false);
                    handleAlerta({'active': true, 'type': 'success', 'message': 'Guardado'});
                    updateTienda();
                }).catch((error)=>{
                    setLoading(false);
                    if(error.response.status === 401) {
                        validarSesionAdmin();
                    }
                })
        }
    }
    
    const changeValueTerminosYCondicione = ({ html, text }) => {
        setTerminosYCondiciones(text);
        setMarkdown(marked(text))
    };

    return(
        <div className="editor-container">
            <h1 className="title">Aviso de privacidad</h1>
            <Container component="main" maxWidth="md">
                <Grid container className="box-container">
                    <Grid item xs={12} sm={12} md={12} >
                    <form  className="formulario" onSubmit={updateAvisoDePrivacidad}>
                    <Grid container className="box-formulario">
                        <Grid item xs={12} sm={6} md={6} className="form-item">
                            <div className="box-input">
                            <div dangerouslySetInnerHTML={{__html: markdown}}></div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} className="form-item">
                            <Editor
                                canView={
                                    { menu: true, md: false, html: false, fullScreen: false, hideMenu: false }
                                }
                                ref={mdEditor}
                                value={terminosYCondiciones}
                                onChange={changeValueTerminosYCondicione}
                                renderHTML={text => <ReactMarkdown source={text} />}
                            />
                            </Grid>
                        </Grid>
                        <Grid container className="box-submit">
                            <Grid item xs={12} sm={12} md={12} >
                                <button type="submit" className="btn-submit">Guardar cambios</button>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </Container>
    </div>
    );
}

export default AvisoDePrivacidad;